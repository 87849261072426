<template lang="pug">
  .users
    p.title Utilizatori

    .counters
      p Total Utilizatori: {{ this.totalUsersCount }}
      p Utilizatori Logati: {{ this.loggedInUsersCount }}
      p Utilizatori Delogati: {{ this.totalUsersCount - this.loggedInUsersCount }}

    .user-wrapper
      el-dialog(title='Adauga Utilizator' :visible.sync='dialogFormVisible').users-dialog
        el-form(:label-position="labelPosition" label-width="100px" :model="formUser")
          el-form-item(label='Nume & Prenume' :label-width='formLabelWidth')
            el-input(v-model='formUser.full_name' autocomplete='off')
          el-form-item(label='Numar de telefon' :label-width='formLabelWidth')
            el-input(v-model='formUser.phone_number' autocomplete='off')
          el-form-item(label='Organizatia Membra' :label-width='formLabelWidth')
            el-input(v-model='formUser.organisation' autocomplete='off')
          el-form-item(label='Parola initiala' :label-width='formLabelWidth')
            el-input(v-model='formUser.password' autocomplete='off')
        .dialog-footer(slot='footer')
          .btn-add
            el-button(@click='addUser' :loading="loading") Adauga Utilizator
          .btn-cancel
            el-button(type='primary' @click='dialogFormVisible = false' class="text-bold") Renunta
      el-dialog(title='Detalii Utilizator' :visible.sync='dialogFormUpdateVisible').users-dialog
        el-form(:label-position="labelPosition" label-width="100px" :model="formUser")
          el-form-item(label='Nume & Prenume' :label-width='formLabelWidth')
            el-input(v-model='formUser.full_name', autocomplete='off')
          el-form-item(label='Numar de telefon' :label-width='formLabelWidth')
            el-input(v-model='formUser.phone_number' autocomplete='off')
          el-form-item(label='Organizatia Membra' :label-width='formLabelWidth')
            el-input(v-model='formUser.organisation' autocomplete='off')
          el-form-item(label='Parola initiala' :label-width='formLabelWidth')
            el-input(v-model='formUser.password' autocomplete='off')
        .dialog-footer(slot='footer')
          .btn-add
            el-button(@click='updateCurrentUser' :loading="loading") Salveaza
          .btn-cancel
            el-button(type='primary' @click='dialogFormUpdateVisible = false' class="text-bold") Renunta
      el-dialog(title='Sterge Utilizator' :visible.sync='dialogFormDeleteVisible').users-dialog
        .delete-text
          p Urmatoarea actiune va sterge toate datele despre utilizator.
          p Sunteti sigur ca doriti sa continuati?
        .dialog-footer(slot='footer')
          .btn-red
            el-button(@click='deleteCurrentUser') Sterge Utilizator
          .btn-cancel
            el-button(type='primary' @click='dialogFormDeleteVisible = false' class="text-bold") Renunta

      el-input.search-box(v-model="search" @input="handleSearch" prefix-icon="el-icon-search" placeholder="Caută după nume" clearable)
      .buttons-wrapper
        .buttons-left
          el-button-group.select-group
            el-button(type='primary' @click="toggleSelection(users)").select-all Selecteaza tot
            el-button(type='primary' @click="toggleSelection()").deselect-all Deselecteaza tot
          el-button(@click='deleteSelected()' style='margin-left: 2%').delete-selections Sterge selectate
        .buttons-right
          el-button(@click="cleanUserForm").add-btn Adauga Utilizator
          el-upload.upload-demo(
            action="#"
            :auto-upload="false"
            :show-file-list	="false"
            :on-change="attachFiles"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            el-button.import-btn Importa lista XLS
      el-table(ref='multipleTable' :data='this.users' style='width: 100%' @current-change='handleRowChange' @selection-change='handleSelectionChange' @sort-change='customSort')
        el-table-column(type='selection' width='50')
        el-table-column(label='Login' width='55')
          <template slot-scope='scope'>
            <span :class="{'dot': true, 'green-dot': scope.row.logged_in, 'red-dot': !scope.row.logged_in}"></span>
          </template>
        </el-table-column>
        el-table-column(property='full_name' sortable="custom" label='Nume' width='320')
        el-table-column(property='phone_number' label='Numar de telefon' width='320')
        el-table-column(property='rewards_key' label='ID' width='320')
        el-table-column(property='organisation' label='Organizatie' width='320' show-overflow-tooltip='')
        el-table-column(label="Actiuni" width='150')
          .action-buttons
            p.edit-btn(@click='handleEditBtnClick') Editeaza
            .divider
            p.delete-btn(@click='handleDeleteBtnClick') Sterge
      el-pagination(layout="prev, pager, next"
                    :total="totalCount"
                    :page-size="1"
                    :current-page="currentPage"
                    @next-click="handlePageClick"
                    @prev-click="handlePageClick"
                    @current-change="handlePageClick")

</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { sortOrderTransform } from '../../utils/sortUtils'

export default {
  name: 'UsersView',
  components: {},
  data() {
    return {
      crudOperation: false,
      labelPosition: 'top',
      formUser: {
        full_name: '',
        phone_number: '',
        organisation: '',
        password: ''
      },
      multipleSelection: [],
      dialogFormVisible: false,
      dialogFormUpdateVisible: false,
      dialogFormDeleteVisible: false,
      formLabelWidth: '120px',
      loading: false,
      search: ''
    };
  },

  computed: {
    ...mapGetters({
      users: 'usersModule/users',
      totalUsersCount: 'usersModule/totalUsersCount',
      loggedInUsersCount: 'usersModule/loggedInUsersCount',
      currentPage: 'usersModule/currentPage',
      totalCount: 'usersModule/totalCount'
    }),
  },

  created() {
    this.updateCurrentPage(1);
    this.clearFilters();
    this.getUsers();
  },

  methods: {
     ...mapActions({
      createUser: 'usersModule/createUser',
      updateUser: 'usersModule/updateUser',
      getUsers:  'usersModule/getUsers',
      deleteUser: 'usersModule/deleteUser',
      importUsers: 'usersModule/importUsers'
    }),

    ...mapMutations('usersModule', [
      'updateCurrentPage', 'updateUserFilters', 'updateSortOptions'
    ]),

    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleEditBtnClick() {
      this.dialogFormUpdateVisible = true;
      this.crudOperation = true;
    },

    handleDeleteBtnClick() {
      this.dialogFormDeleteVisible = true;
      this.crudOperation = true;
    },

    cleanUserForm() {
      this.formUser.full_name = '';
      this.formUser.phone_number = '';
      this.formUser.organisation = '';
      this.formUser.password = '';
      this.crudOperation = true;
      this.dialogFormVisible = true;
    },
    handlePageClick(page) {
      this.updateCurrentPage(page);
      this.getUsers();
    },

    clearFilters() {
      this.updateUserFilters({ key: 'with_full_name',
                               value: null })
    },

    handleSearch() {
      this.updateCurrentPage(1);

      if (this.search == '')
        this.updateUserFilters({ key: 'with_full_name',
                                 value: null })
      else
        this.updateUserFilters({ key: 'with_full_name',
                                 value: this.search })

      this.getUsers();
    },

    customSort({ prop, order }) {
      this.updateSortOptions({ column: `order_by_${prop}`,
                               direction: sortOrderTransform(order) })

      this.getUsers();
    },

    attachFiles(file) {
      const formData = new FormData();
      formData.append('users[file][]', file.raw);

      this.importUsers(formData).then(({ errors, imported }) => {
        let type = 'success';
        let message = ''
        if (imported.length > 0) {
          const names = imported.join(', ');
          message += `Au fost importati cu success ${imported.length} utilizatori: ${names}.`
        }
        if (errors.length > 0) {
          type = 'error';
          message += '<br>Erori:<br>'
          message += errors.join('<br>');
        }
        this.$message({ dangerouslyUseHTMLString: true, message, type, duration: 5000 });
      })
    },

    async addUser() {
      this.loading = true;
      var result = await this.createUser({user: this.formUser});
      if (result) {
        this.dialogFormVisible = false;
        this.crudOperation = false;
      }
      this.loading = false;
    },

    deleteCurrentUser() {
      var result = this.deleteUser(this.formUser.id);
      this.dialogFormDeleteVisible = false;
      this.crudOperation = false;
    },

    deleteSelected() {
      var result = this.deleteUser(this.multipleSelection.map(a => a.id));
    },

    handleRowChange(val) {
      if (this.crudOperation == true) {
        this.formUser.id = val.id;
        this.formUser.full_name = val.full_name;
        this.formUser.phone_number = val.phone_number;
        this.formUser.organisation = val.organisation;
        this.formUser.password = val.password;
      }
    },

    async updateCurrentUser() {
      this.loading = true;
      var result = await this.updateUser({ user: this.formUser });
      if (result) {
        this.dialogFormUpdateVisible = false;
        this.crudOperation = false;
      }
      this.loading = false;
    },

    handleClose(done) {
      done();
    }
  },
};

</script>

<style scoped lang="scss">
  .users {
    width: 100%;
    margin: 0px 68px 51px 68px;

    .user-wrapper {
      background-color: white;
      padding: 15px 15px 29px;
      height: auto;
      width: 1214px;

      .el-dialog{
        width: 24% !important;
      }
    }

    .upload-demo{
      max-width: 151px;;
      display: inline-block;

      .el-button {
        margin-left: 10%;
      }
    }

    .buttons-wrapper {
      width:100%;
      margin-top: 10px;
      margin-bottom: 60px;
      text-align: center;
      margin-left: 1%;
    }

    .select-all{
      color: #EDC731;
      font-family: PTSans;
      font-size: 14px;
      font-weight: 400;
      text-align: left
      width 50;
      height: 100%;
    }

    .delete-selections {
      border: 1px solid #A2A7B0;
      border-radius: 4px;
      height: 36px;
      float: left;
      display: inline-block;
      color: #A2A7B0;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .search-box {
      margin: 10px 1%;
      width: 300px;
    }

    .deselect-all{
      color: #C0C4CC;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
      height: 100%;
      text-align: left;
    }

    .title {
      color: #02386D;
      font-family: 'PTSans-Bold';
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .select-group {
      border-radius: 4px;
      width: 275px;
      height: 36px;
      display: inline-block;
      float: left;
    }

    .buttons-right{
      display: flex;
      float: right;
      margin-right: 4%;
    }

    .add-btn {
      background-color: #EDC731;
      border-radius: 4px;
      width: 188px;
      height: 40px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    .import-btn {
      border: 1px solid #EDC731;
      border-radius: 4px;
      width: 151px;
      height: 40px;
      color: #EDC731;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

  .edit-btn{
    color: #1E7CD7;
    font-size: 14px;
    font-weight: 400;
    float: left;
    cursor: pointer;
  }

  .delete-btn{
    color: #FA5555;
    font-size: 14px;
    font-weight: 400;
    float: right;
    cursor: pointer;
  }

  .counters {
    margin-bottom: 20px;
  }

  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
  }

  .green-dot {
    background-color: green;
  }

  .red-dot {
    background-color: red;
  }
}
</style>
